body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  @media only screen and (max-width: $break-sm) {
    overflow: auto;
    height: auto;
  }
}

.errHolder
{
  color: #FFFFFF;
  margin-bottom: 20px;
  display: none;
}

.login-wrapper
{
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  color: #888888;

  .login-wrapper-text
  {
    @media only screen and (max-width: $break-md) {
      display: none;
    }    
  }
}