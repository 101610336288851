.btn {
  box-shadow: none;
  padding: 13px 26px;
  border-radius: 100px;
  border: 1px solid $primary;
  background: $primary;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover, &:focus {
    background-color: darken($primary, 5%);
    border: 1px solid darken($primary, 5%);
    outline: none;
  }
}