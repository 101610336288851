@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.quickFlex {
  display: flex;
  align-items: center;
}

.display-none {
  display: none!important;
}

.align-right-margin {
  margin: 0 0 0 auto;
}

.no-margin {
  margin: 0;
}

.margin-b5 {
  margin-bottom: 5px;
}

.margin-b10 {
  margin-bottom: 10px;
}

.margin-b15 {
  margin-bottom: 15px;
}

.margin-b20 {
  margin-bottom: 20px;
}

.margin-b25 {
  margin-bottom: 25px;
}

.margin-b30 {
  margin-bottom: 30px;
}

.margin-b35 {
  margin-bottom: 35px;
}

.margin-b40 {
  margin-bottom: 40px;
}

.margin-b45 {
  margin-bottom: 45px;
}

.margin-b50 {
  margin-bottom: 50px;
}

.margin-b55 {
  margin-bottom: 55px;
}

.margin-b60 {
  margin-bottom: 60px;
}

.hide-mobile {
  @media only screen and (max-width: $break-sm) {
    display: none;
  }
}