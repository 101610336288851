input[type=text], [type=email], [type=password], select {
  width: 100%;
  padding: 10px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  font-size: 14px;
  border: 2px solid white;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    color: $grey;
    font-size: 14px;
  }
  &:hover, &:focus {
    border: 2px solid $primary;
    outline: none;
  }
}