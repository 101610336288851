.logo-wrapper {
  position: absolute;
  top: 0;
  left: 90px;
  width: 120px;
  height: 44px;
  background: linear-gradient(to right, $secondary , $primary);
  z-index: 1;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: $break-md) {
    left: 60px;
  }
  @media only screen and (max-width: $break-sm) {
    left: 30px;
  }
}