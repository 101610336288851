.slide-wrapper {
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @media only screen and (max-width: $break-sm) {
    position: relative;
    overflow: auto;
  }
  .slider {
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    
    -webkit-transition: -webkit-transform 1600ms;
    transition: -webkit-transform 1600ms, transform 1600ms;
    -webkit-transform: scale(1);
    transform: scale(1);
    
    @media only screen and (max-width: $break-sm) {
      height: auto;
      position: relative;
      display: flex !important;
    }
    .left {
      width: 50%;
      height: 100%;
      padding: 120px 90px 90px 90px;
      box-sizing: border-box;
      margin-top: 0;
      opacity: 1;
      

      @media only screen and (min-width: $break-sm) {
        transform: scale(1);
      transition: all 700ms;
        }

      &.offScreenTop
      {
        opacity: 0;
        margin-top: 100vh;
        @media only screen and (max-width: $break-sm) {
          opacity: 1;
          margin-top: 0;
          }
      }
      &.offScreenBottom
      {
        opacity: 0;
        margin-top: -100vh;
        @media only screen and (max-width: $break-sm) {
          opacity: 1;
          margin-top: 0;
          }
      }

      @media only screen and (max-width: $break-md) {
        padding: 90px 60px 60px 60px;
      }
      @media only screen and (max-width: $break-sm) {
        padding: 90px 30px 30px 30px;
        width: 100%;
        height: auto;
      }
      &.alt {
        @media only screen and (max-width: $break-sm) {
          padding: 60px 30px 30px 30px;
        }
      }
    }
    .right {
      position: relative;
      width: 50%;
      height: 100%;
      padding: 90px;
      box-sizing: border-box;
      background-image: url("/images/graffitti.jpeg");
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      opacity: 1;
      @media only screen and (min-width: $break-sm) {
        transform: scale(1);
      transition: all 700ms;
        }
      &.offScreenTop
      {
        opacity: 0;
        margin-top: 100vh;
        @media only screen and (max-width: $break-sm) {
        opacity: 1;
        margin-top: 0;
        }
      }
      &.offScreenBottom
      {
        opacity: 0;
        margin-top: -100vh;
        @media only screen and (max-width: $break-sm) {
          opacity: 1;
          margin-top: 0;
          }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(51,37,126,0.85);
      }
      &.alt {
        background: lighten($grey, 35%);
        &:before {
          background: initial;
        }
      }
      .content {
        position: relative;
        width: 100%;
        &.alt {
          height: 100%;
          box-shadow: 0px 0px 30px lighten($grey, 20%);
          border-radius: 4px;
          background-size: cover;
          background-repeat: no-repeat;
          @media only screen and (max-width: $break-sm) {
            height: 360px;
          }
        }
      }
      @media only screen and (max-width: $break-md) {
        padding: 30px;
      }
      @media only screen and (max-width: $break-sm) {
        width: 100%;
        padding: 60px 30px;
        position: relative;
        height: auto;
      }
    }
  }
}