.navigation {
  position: absolute;
  bottom: 90px;
  left: 90px;
  width: calc(50% - 180px);
  z-index: 2;
  ul {
    margin: 0 0 0 30px;
    padding: 0;
    li {
      display: inline-block;
      list-style: none;
      margin-left: 1px;
      &:first-child {
        margin-left: 0;
      }
      a {
        font-size: 28px;
        text-decoration: none;
        color: $primary;
        @include transition(all .3s ease);
        &:hover {
          color: darken($primary, 10%);
        }
        &.disabled {
          color: $grey;
          opacity: 0.5;
          cursor: initial;
          &:hover {
            color: $grey;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $break-md) {
    left: 60px;
    bottom: 60px;
  }
  @media only screen and (max-width: $break-sm) {
    left: 30px;
    display: none;
  }
}