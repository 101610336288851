h1, h2, h3, h4,p {
  margin: 0 0 30px 0;
}

h1, h2, h3, h4 {
  font-weight: 700;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 31px;
}

h4 {
  font-size: 26px;
}

.text-light {
  font-weight: 300;
}

.text-heavy {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-xsm {
  font-size: 12px
}

.text-sm {
  font-size: 14px
}

.text-md {
  font-size: 16px
}

.text-lg {
  font-size: 22px
}

.text-grey {
  color: $grey;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-white {
  color: white;
}